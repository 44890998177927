import React from 'react';
import './Home.css';  // Create a CSS file for Home-specific styles

import BannerBackground from "../assets/screenshots/home-banner-background.png";
import BannerImage from "../assets/screenshots/gcse_img1.png";
import AppStoreBadge from "../assets/images/app-store-badge.png"
import GoogleStoreBadge from "../assets/images/google-play-badge.png"



const Home = () => {
  return (
    <section id="home">
    <>
      <div className="home-banner-container">
          <div className="home-bannerImage-container">
            <img src={BannerBackground} alt="" />
          </div>
          <div className="home-text-section">
            <h1>
            Master GCSE Maths with Your AI Tutor!
            </h1>
          </div>
          <div className="home-subtext">
            Unlock 3000+ practice questions, 50+ tests, and personalized AI guidance to boost your confidence and scores. Start your journey to success today!
          </div>
          <div className="home-button-section">
            <div className="store-badge-container">
                <a href="https://apps.apple.com/us/app/the-gcse-maths-app/id1224976510?platform=iphone" target="_blank" rel="noopener noreferrer">
                    <img src={AppStoreBadge} alt="Download on the App Store" className="store-badge" />
                </a>
            </div>
            <div className="store-badge-container">
                <a href="https://play.google.com/store/apps/details?id=uk.co.aerionlabs.gcsemathstestapp" target="_blank" rel="noopener noreferrer">
                    <img src={GoogleStoreBadge} alt="Get it on Google Play" className="store-badge" />
                </a>
        </div>
    </div>
          <div className="home-image-section">
            <img src={BannerImage} alt="" />
          </div>

      </div>
      </>
      </section>

  );
}


export default Home;
