// CourseContents.js

import React, { useState, useEffect } from 'react';
import './CourseContents.css';  // Create a CSS file for CourseContents-specific styles
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

import { useNavigate } from 'react-router-dom';
import log from '../utils/logger'; // Adjust the import path as needed


const CourseContents = () => {

    const [chapters, setChapters] = useState([]);
    const [selectedChapter, setSelectedChapter] = useState(null);
    const [selectedTopic, setSelectedTopic] = useState(null);

    useEffect(() => {
        fetch('http://localhost:8000/api/chapters/')
            .then(response => response.json())
            .then(data => setChapters(data.chapters))
            .catch(error => console.error('Error fetching courses:', error));
    }, []);

    const handleChapterClick = (chapter) => {
        setSelectedChapter(chapter);
    };

    const handleBackToChapters = () => {
        setSelectedChapter(null);
    };

    const handleTopicClick = (topic) => {
        setSelectedTopic(topic);
    };

    const handleBackToTopics = () => {
        setSelectedTopic(null);
    };

    const navigate = useNavigate();

    const handleProceed = () => {

   //     const savedProgress = localStorage.getItem(`progress-${selectedChapter.name}-${selectedTopic}`);
   //     log(`CourseContent.js Line45: savedProgress is ${savedProgress} in progress-${selectedChapter.name}-${selectedTopic}`)

        if (selectedChapter && selectedTopic) {
            navigate(`/questions/${encodeURIComponent(selectedChapter.name)}/${encodeURIComponent(selectedTopic)}`);
        }
    };

  return (
    <div className="courseContent-banner-container">

        <p className="courseContent-primary-subheading">Course Content</p>
        <h1 className="courseContent-primary-heading">Practice from 10 Chapters</h1>
        <p className="courseContent-primary-text">
          Lorem ipsum dolor sit amet consectetur. Non tincidunt magna non et
          elit. Dolor turpis molestie dui magnis facilisis at fringilla quam.
        </p>

        {selectedTopic ? (
                <div className="instruction-container">
                    <h2 className = "instruction-centered-title">  Instructions for {selectedTopic}</h2>
                    <p className = "instruction-centered-text">Here are the detailed instructions on how to handle questions for {selectedTopic}.</p>
                    <div className="instruction-button-section">
                        <button className="instruction-secondary-button" onClick={handleProceed}>Proceed</button>
                        <button className="instruction-secondary-button" onClick={handleBackToTopics}>Back to Topics</button>
                    </div>

                </div>
        ) : selectedChapter ? (
                <div className="topics-container ">
                    <h2 className="topic-centered-title">{selectedChapter.name}</h2>
                    <div className="topics-grid">
                        
                        
                        {selectedChapter.topics.map((topic, index) => (
                          <div key={index} className="topic-box" onClick={() => handleTopicClick(topic)}>
                                {topic}
                                <FontAwesomeIcon icon={faArrowRight} />
                            </div>
                        ))}
                    </div>
                    <div className="topic-button-section">
                        <button className="topic-secondary-button" onClick={handleBackToChapters}>Back to Chapters</button>
                    </div>

                </div>

        ) : (
                <div className="chapters-grid">
                    {chapters.map((chapter, index) => (
                        <div key={index} className="chapter-box" onClick={() => handleChapterClick(chapter)}>
                            <h2>{chapter.name}</h2>
                            <p>Practice from {chapter.topics.length} topics</p>
                        </div>
                    ))}
                </div>
            )}
     </div>
  );
}

export default CourseContents;
