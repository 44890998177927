import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link'; // If you want smooth scroll, install `react-router-hash-link`
import './Navbar.css';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChess } from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-solid-svg-icons';

const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -60; // Adjust this value based on your fixed header size or any offset you want
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
}


const Navbar = () => {
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false); // State to manage popup visibility



    return (
      <nav className="navbar">
        <div className='navbar-container'>
          <Link to="/#home" scroll={el => scrollWithOffset(el)} className='navbar-logo'> 
              ACE GCSE <FontAwesomeIcon icon={faChess} /> 
          </Link>
        </div>
          <ul className="nav-links">
              <li><Link to="/#home" scroll={el => scrollWithOffset(el)}>Home</Link></li>
              <li><Link to="/#about" scroll={el => scrollWithOffset(el)}>About</Link></li>
              <li><Link to="/#chapters" scroll={el => scrollWithOffset(el)}>Chapters</Link></li>
              <li><Link to="/#testimonial" scroll={el => scrollWithOffset(el)}>Testimonial</Link></li>
              <li><Link to="/#contact-us" scroll={el => scrollWithOffset(el)}>Contact Us</Link></li>
          </ul>
      </nav>
    );
  };

export default Navbar;
