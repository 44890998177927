import React from 'react';
import './Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';

function Footer() {
    return (
        <section id="contact-us">
  <footer className="footer">
    <div className="footer-container">
      {/* Quick Links */}
      <nav className="footer-links">
        <h3>Quick Links</h3>
        <ul>
          <li><a href="#about">About Us</a></li>
          <li><a href="#chapters">Chapters</a></li>
          <li><a href="#testimonial">Testimonials</a></li>
          <li><a href="#contact-us">Contact Us</a></li>
        </ul>
      </nav>

      {/* Contact Info */}
      <address className="footer-contact">
        <h3>Contact Us</h3>
        <p><FontAwesomeIcon icon={faPhone} /> +44 1234 567 890</p>
        <p><FontAwesomeIcon icon={faEnvelope} /> aerion.labs@gmail.com</p>
      </address>

      {/* Social Media */}
      <div className="footer-social">
        <h3>Follow Us</h3>
        <div className="social-icons">
          <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faFacebook} />
          </a>
          <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faTwitter} />
          </a>
          <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faInstagram} />
          </a>
        </div>
      </div>
    </div>

    {/* Footer Bottom */}
    <div className="footer-bottom">
      <p>© 2024 GCSE Maths | All Rights Reserved</p>
    </div>
  </footer>
</section>

    );
}

export default Footer;
