import React from 'react';
import './About.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRobot, faClipboardCheck, faChartLine, faGraduationCap } from '@fortawesome/free-solid-svg-icons';

import { Link } from 'react-router-dom'; // Add this line

const About = () => {
  return (
    <section id="about">
      <div className="about-container">
        {/* Orange "About" subheading */}
        <h3 className="about-orange-subheading">About</h3>

        {/* Right side summary */}
        <div className="about-right">
          <h2 className="about-primary-heading">Ace Your GCSE Maths with Confidence</h2>
          <p className="about-subtext">
            Learn by practicing thousands of questions across all topics with AI assistance to track your performance and test your knowledge.
          </p>
          <div className="about-button-section">
            <Link to="/learn-more">
              <button className="about-secondary-button">
                Learn More &nbsp; <i class="fa-solid fa-video"></i>
              </button>
            </Link>
          </div>
        </div>


        {/* Left side list */}
        <ul className="about-list">
        <li className="about-item">
            <div className="about-icon-container">
              <FontAwesomeIcon icon={faRobot} className="about-icon" />
            </div>
            <div className="about-content">
              <h4 className="about-title">AI-Powered Tutor</h4>
              <p className="about-description">
              Get instant AI assistance with step-by-step explanations, turning every question into a learning opportunity!              </p>
            </div>
          </li>

          <li className="about-item">
            <div className="about-icon-container">
              <FontAwesomeIcon icon={faGraduationCap} className="about-icon" />
            </div>
            <div className="about-content">
              <h4 className="about-title">3000+ Practice Questions</h4>
              <p className="about-description">
                 Explore 3000+ questions covering all six GCSE Maths chapters, designed for all levels to build strong fundamentals and tackle advanced problems.              </p>
            </div>
          </li>
          <li className="about-item">
            <div className="about-icon-container">
              <FontAwesomeIcon icon={faClipboardCheck} className="about-icon" />
            </div>
            <div className="about-content">
              <h4 className="about-title">100+ Pop and Mock Quizzes</h4>
              <p className="about-description">
              Boost confidence with chapter-specific and full-syllabus quizzes, simulating real exam conditions to test your knowledge effectively              </p>
            </div>
          </li>

          <li className="about-item">
            <div className="about-icon-container">
              <FontAwesomeIcon icon={faChartLine} className="about-icon" />
            </div>
            <div className="about-content">
              <h4 className="about-title">Track Your Progress</h4>
              <p className="about-description">
              Track your progress, pinpoint weaknesses, and get tailored study insights to stay ahead!              </p>
            </div>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default About;
