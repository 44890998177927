import React from "react";
import ProfilePic from '../assets/images/john-doe-image.png';
import Numbers from "../assets/images/numbers.png";
import Algebra from "../assets/images/algebra.png";
import Geometry from "../assets/images/geometry.png";
import Statistics from "../assets/images/statistics.png";
import Probability from "../assets/images/probability.png";
import Ratio from "../assets/images/ratio.png";

import './Chapters.css';

const Chapters = () => {
  const chapterData = [
    {
      image: Numbers,
      name: "Numbers",
      text: "Hone vital arithmetic abilities like fractions, decimals, percentages, rates, proportions, units, and more.",
    },
    {
      image: Algebra,
      name: "Algebra",
      text: "Practice essential algebra skills like solving equations, graphing functions, exponentials, and logarithms.",
    },
    {
      image: Geometry,
      name: "Geometry",
      text: "Master important geometry concepts including triangles, circles, polygons, angles, transformations, and more.",
    },
    {
      image: Statistics,
      name: "Statistics",
      text: "Improve your statistics skills including data interpretation, mean, median, permutations, and combinations.",
    },
    {
      image: Ratio,
      name: "Ratio",
      text: "Understand ratio concepts like sine, cosine, tangent functions, trigonometric identities, and solving trig equations.",
    },
    {
      image: Probability,
      name: "Probability",
      text: "Develop probability skills including permutations, combinations, and their applications.",
    },
  ];

  return (
    <section id="chapters">
      <div className="chapters-container">
        <p className="chapters-primary-subheading">Chapters</p>
        <h1 className="chapters-primary-heading">
          GCSE Maths Questions for Dedicated Practice
        </h1>
        <p className="chapters-primary-text">
          We have organized all GCSE Maths topics into chapters and subtopics so you can easily find questions to practice the areas you need to improve.
        </p>
        <div className="chapters-images-container">
          {chapterData.map((data, index) => (
            <div className="chapters-section-info" key={index}>
              <div className="chapters-img-container">
                <img src={data.image} alt={data.name} />
              </div>
              <h2>{data.name}</h2>
              <p>{data.text}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Chapters;
