import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Navbar from './components/Navbar';
import Home from './pages/Home';
import About from './pages/About';
import Chapters from './pages/Chapters';
import Testimonial from './pages/Testimonial';
import CourseContents from './pages/CourseContents';
import LearnMore from './pages/LearnMore';
import Footer from './pages/Footer';

import './App.css';


const App = () => {
  return (
    <Router>
      <div>
        <Navbar />
        <Routes>
        <Route path="/" element={
          <>
            <Home />
            <About />
            <Chapters />
            <Testimonial />
            <Footer/>
          </>
        } />
          <Route path="/course-contents" element={<CourseContents />} />
          <Route path="/learn-more" element={<LearnMore />} />

        </Routes>
      </div>
    </Router>
  );
};

export default App;
