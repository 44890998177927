import React, { useEffect } from 'react';
import './LearnMore.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookOpen, faUserGraduate, faChartBar } from '@fortawesome/free-solid-svg-icons';
//import Testimonial from '../../Assets/testimonial-image.png';
import Testimonial from  "../assets/screenshots/gcse_img1.png";

const LearnMore = () => {

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top of the page
      }, []);

      const screenshots = [
        { src: require('../assets/screenshots/screenshot1.png'), description: 'UK #1 GCSE App. Your ultimate tool for success!' },
        { src: require('../assets/screenshots/screenshot2.png'), description: 'Meet Your AI Tutor. Instant Help for GCSE Maths!' },
        { src: require('../assets/screenshots/screenshot3.png'), description: 'Master All 6 Topics with 3000+ Expertly Designed Questions' },
        { src: require('../assets/screenshots/screenshot4.png'), description: 'Test Your Knowledge: Practice Real GCSE Questions' },
        { src: require('../assets/screenshots/screenshot5.png'), description: 'Explore Subtopics: Dive Into Each Concept' },
        { src: require('../assets/screenshots/screenshot6.png'), description: 'Master Concepts with Flashcards Anytime, Anywhere' },
      ];

      
  return (
    <section id="learn-more">
      <div className="learn-more-container">
        {/* Header Section */}
        <header className="learn-more-header">
          <h1>Why Choose Our GCSE Maths Platform?</h1>
          <p>Explore our comprehensive features designed to help you master GCSE Maths with confidence.</p>
        </header>

        {/* Features Section */}
        <div className="learn-more-features">
          <div className="feature-card">
            <FontAwesomeIcon icon={faBookOpen} className="feature-icon" />
            <h3>Comprehensive Practice</h3>
            <p>Access over 3000 practice questions across all chapters and subtopics.</p>
          </div>
          <div className="feature-card">
            <FontAwesomeIcon icon={faUserGraduate} className="feature-icon" />
            <h3>Mock Quizzes</h3>
            <p>Test your knowledge with mock quizzes and full-course tests to prepare for your exams.</p>
          </div>
          <div className="feature-card">
            <FontAwesomeIcon icon={faChartBar} className="feature-icon" />
            <h3>AI-Powered Insights</h3>
            <p>Track your performance and get detailed insights to focus on your weak areas.</p>
          </div>
        </div>

     {/* App Screenshots Section */}
     <div className="learn-more-screenshots">
          {screenshots.map((screenshot, index) => (
            <div key={index} className="screenshot-item">
              <img src={screenshot.src} alt={`Screenshot ${index + 1}`} className="screenshot-image" />
              <p className="screenshot-description">{screenshot.description}</p>
            </div>
          ))}
        </div>


        {/* Testimonials Section */}
        <div className="learn-more-testimonials">
          {/* Testimonial Header */}
          <div className="testimonial-heading">
            <h2>What Our Students Say</h2>
          </div>

          {/* Testimonials */}
          <div className="testimonial-texts">
            <div className="testimonial-item">
              <p>"This platform revolutionized how I prepared for GCSE Maths! The questions, quizzes, and AI guidance transformed my confidence and performance."</p>
              <span>- Alex, Student</span>
            </div>
            <div className="testimonial-item">
              <p>"The mock quizzes perfectly mimicked real exams, giving me the practice and confidence I needed to succeed. I can't recommend it enough!"</p>
              <span>- Sarah, Student</span>
            </div>
            <div className="testimonial-item">
              <p>"With thousands of questions and insightful progress tracking, this platform ensured I mastered every topic for my exams!"</p>
              <span>- Liam, Student</span>
            </div>
            <div className="testimonial-item">
              <p>"Identifying and improving my weak areas made a huge difference. The tailored feedback boosted my scores and my confidence."</p>
              <span>- Emma, Student</span>
            </div>
          </div>


        </div>


        {/* Call-to-Action */}
        <div className="learn-more-cta">
          <p>Ready to excel in GCSE Maths? Download our app now!</p>
          <div className="cta-buttons">
            <a href="https://apps.apple.com/us/app/the-gcse-maths-app/id1224976510?platform=iphone" target="_blank" rel="noopener noreferrer">
              <img
                src={require("../assets/images/app-store-badge.png")}
                alt="Download on the App Store"
                className="store-badge"
              />
            </a>
            <a href="https://play.google.com/store/apps/details?id=uk.co.aerionlabs.gcsemathstestapp" target="_blank" rel="noopener noreferrer">
              <img
                src={require("../assets/images/google-play-badge.png")}
                alt="Get it on Google Play"
                className="store-badge"
              />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LearnMore;
