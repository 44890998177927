import React from "react";
import ProfilePic from "../assets/images/john-doe-image.png";
import Student1 from "../assets/images/student1.jpg";
import Student2 from "../assets/images/student2.jpg";
import Student3 from "../assets/images/student3.jpg";

import { AiFillStar } from "react-icons/ai";
import './Testimonial.css';  // Create CSS for About layout

const Testimonial = () => {

    const testimonialData = [
        {
          image: Student1,
          name: "Olivia",
          text: "Using this platform was the best decision for my GCSE Maths prep! The detailed explanations and personalized guidance made even the hardest topics manageable. I went from struggling to scoring top marks in no time!",
        },
        {
          image: Student2,
          name: "George",
          text: "I loved the AI tutor and the mock quizzes! They helped me identify my weak areas and improve steadily. Now I feel confident tackling any question in the exam! ",
        },
        {
          image: Student3,
          name: "Paul",
          text: "The variety of practice questions and the progress tracking tools kept me motivated throughout my GCSE Maths preparation. I couldn’t have done it without this amazing resource!",
        },
      ];

  return (
    <section id="testimonial">

<div className="testimonial-container">
  <p className="testimonial-primary-subheading">Testimonial</p>
  <h1 className="testimonial-primary-heading">What They Are Saying</h1>
  <p className="testimonial-primary-text">
    Hear from our users who excelled in GCSE Maths with the help of our platform.
  </p>

  <div className="testimonial-section-bottom">
    {testimonialData.map((data, index) => (
      <div className="testimonial-section-info" key={index}>
        <div className="info-boxes-img-container">
          <img src={data.image} alt={data.name} />
        </div>
        <p>{data.text}</p>
        <div className="testimonials-stars-container">
          {[...Array(5)].map((_, i) => (
            <AiFillStar key={i} />
          ))}
        </div>
        <h2>{data.name}</h2>
      </div>
    ))}
  </div>
</div>

    </section>

  );
};

export default Testimonial;
